import { createElement } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { OrderFulfillmentStatus } from '@one/api-models/lib/Membership/Account/OrderFulfillmentStatus';
import { OrderPaymentStatus } from '@one/api-models/lib/Membership/Account/OrderPaymentStatus';
import { OrderStatus } from '@one/api-models/lib/Membership/Account/OrderStatus';

import { StatusColors } from './StatusColors';

export type StatusOption = {
  name: string;
  backgroundColor: string;
  color: string;
  icon?: JSX.Element;
};

export type OrderStatusOptions = {
  [key: string]: StatusOption;
};

export const orderStatusOptions: OrderStatusOptions = {
  [OrderStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderStatus.Draft]: {
    name: 'Draft',
    backgroundColor: StatusColors.Draft.background,
    color: StatusColors.Draft.label,
  },
  [OrderStatus.OrderCreated]: {
    name: 'Created',
    backgroundColor: StatusColors.OrderCreated.background,
    color: StatusColors.OrderCreated.label,
  },
  [OrderStatus.OrderConfirmed]: {
    name: 'Confirmed',
    backgroundColor: StatusColors.OrderConfirmed.background,
    color: StatusColors.OrderConfirmed.label,
  },
  [OrderStatus.OrderCompleted]: {
    name: 'Completed',
    backgroundColor: StatusColors.OrderCompleted.background,
    color: StatusColors.OrderCompleted.label,
    icon: createElement(DoneIcon),
  },
  [OrderStatus.OrderCancelled]: {
    name: 'Canceled',
    backgroundColor: StatusColors.OrderCancelled.background,
    color: StatusColors.OrderCancelled.label,
  },
  [OrderStatus.OrderRefunded]: {
    name: 'Refunded',
    backgroundColor: StatusColors.OrderRefunded.background,
    color: StatusColors.OrderRefunded.label,
  },
  [OrderStatus.OrderArchived]: {
    name: 'Archived',
    backgroundColor: StatusColors.OrderArchived.background,
    color: StatusColors.OrderArchived.label,
  },
  [OrderStatus.Failed]: {
    name: 'Failed',
    backgroundColor: StatusColors.Failed.background,
    color: StatusColors.Failed.label,
  },
  [OrderStatus.CancellationRequested]: {
    name: 'Cancellation Requested',
    backgroundColor: StatusColors.CancellationRequested.background,
    color: StatusColors.CancellationRequested.label,
  },
  [OrderStatus.CancellationInProgress]: {
    name: 'Cancellation In Progress',
    backgroundColor: StatusColors.CancellationInProgress.background,
    color: StatusColors.CancellationInProgress.label,
  },
};

export const orderFulfillmentStatusOptions: OrderStatusOptions = {
  [OrderFulfillmentStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderFulfillmentStatus.FulfillmentInitiated]: {
    name: 'Initiated',
    backgroundColor: StatusColors.FulfillmentInitiated.background,
    color: StatusColors.FulfillmentInitiated.label,
  },
  [OrderFulfillmentStatus.FulfillmentCompleted]: {
    name: 'Fulfilled',
    backgroundColor: StatusColors.FulfillmentCompleted.background,
    color: StatusColors.FulfillmentCompleted.label,
    icon: createElement(DoneIcon),
  },
};

export const orderPaymentStatusOptions: OrderStatusOptions = {
  [OrderPaymentStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderPaymentStatus.Unpaid]: {
    name: 'Unpaid',
    backgroundColor: StatusColors.Unpaid.background,
    color: StatusColors.Unpaid.label,
  },
  [OrderPaymentStatus.PartiallyPaid]: {
    name: 'Partially Paid',
    backgroundColor: StatusColors.PartiallyPaid.background,
    color: StatusColors.PartiallyPaid.label,
  },
  [OrderPaymentStatus.FullyPaid]: {
    name: 'Fully Paid',
    backgroundColor: StatusColors.FullyPaid.background,
    color: StatusColors.FullyPaid.label,
    icon: createElement(DoneIcon),
  },
  [OrderPaymentStatus.PartiallyRefunded]: {
    name: 'Partially Refunded',
    backgroundColor: StatusColors.PartiallyRefunded.background,
    color: StatusColors.PartiallyRefunded.label,
  },
  [OrderPaymentStatus.FullyRefunded]: {
    name: 'Fully Refunded',
    backgroundColor: StatusColors.FullyRefunded.background,
    color: StatusColors.FullyRefunded.label,
  },
  [OrderPaymentStatus.Cancelled]: {
    name: 'Cancelled',
    backgroundColor: StatusColors.Cancelled.background,
    color: StatusColors.Cancelled.label,
  },
  [OrderPaymentStatus.AwaitingPayment]: {
    name: 'Awaiting Payment Authorization',
    backgroundColor: StatusColors.AwaitingPayment.background,
    color: StatusColors.AwaitingPayment.label,
  },
  [OrderPaymentStatus.PendingPaymentAuthorization]: {
    name: 'Pending Payment Authorization',
    backgroundColor: StatusColors.PendingPaymentAuthorization.background,
    color: StatusColors.PendingPaymentAuthorization.label,
  },
};
