import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';
import { MetadataKey } from '@one/api-models/lib/Metadata/MetadataKey';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { Brand } from 'models/Brand';
import { selectActiveBrand } from 'slices/applicationDataSlice';
import { IconButton, Typography } from 'styled';

import { EditMetadataDialog } from './EditMetadataDialog';

interface SalesOrderMetadataProps {
  metadata: Metadata[];
  metadataKeys: MetadataKey[];
  handleSave: (metadata: Metadata[]) => void;
  entityId?: string;
}
export const SalesOrderMetadata = ({ metadata, metadataKeys, entityId, handleSave }: SalesOrderMetadataProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const activeBrand = useSelector(selectActiveBrand);

  //Temp workaround until BE issues are fix
  if (activeBrand?.key !== Brand.ExploreByPuebloBonito) return null;

  const columns: GridColDef[] = [
    {
      field: 'metadataKey',
      headerName: 'Key',
      flex: 1,
    },
    {
      field: 'metadataValue',
      headerName: 'Value',
      flex: 1,
    },
  ];

  return (
    <Box sx={{ mt: 7 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Metadata</Typography>
        {!entityId && (
          <IconButton
            onClick={() => setOpen(true)}
            data-testid="EditSalesOrderMetadataButton"
            sx={{
              height: 28,
              width: 28,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              border: `1px solid ${theme.customPalette.input.border}`,
              boxShadow: '0px 2px 4px 0px rgba(50, 50, 93, 0.05), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05)',
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      {metadata.length > 0 ? (
        <Box
          sx={{
            mt: 2.5,
          }}
        >
          <DataGrid
            rows={metadata.map((item: Metadata) => ({ ...item, id: item.metadataKey }))}
            columns={columns}
            rowHeight={40}
            hideFooter={true}
          />
        </Box>
      ) : (
        <Box
          onClick={() => !entityId && setOpen(true)}
          sx={{
            mt: 2.5,
            height: 150,
            borderRadius: 8,
            border: `1px dashed ${theme.customPalette.input.border}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body1" color="#D5DBE1">
            No metadata
          </Typography>
        </Box>
      )}
      <EditMetadataDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleSave={handleSave}
        testId="salesOrderMetadata"
        metadata={metadata}
        metadataKeys={metadataKeys}
        entityId={entityId}
      />
    </Box>
  );
};
