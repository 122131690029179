import { Colors } from './Colors';

export const StatusColors = {
  // Order Status
  Unknown: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  Draft: { background: Colors.softBlue, label: Colors.vividBlue },
  OrderCreated: { background: Colors.brightYellow, label: Colors.mutedBrown },
  OrderConfirmed: { background: Colors.paleYellow, label: Colors.mutedBrown },
  OrderCompleted: { background: Colors.lightGreen, label: Colors.darkGreen },
  OrderCancelled: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  OrderRefunded: { background: Colors.softRed, label: Colors.vividRed },
  OrderArchived: { background: Colors.lightGray, label: Colors.darkGrayBlue },
  Failed: { background: Colors.softRed, label: Colors.vividRed },
  CancellationRequested: { background: Colors.brightOrange, label: Colors.white },
  CancellationInProgress: { background: Colors.brightOrange, label: Colors.white },

  // Fulfillment Status
  FulfillmentInitiated: { background: Colors.paleYellow, label: Colors.mutedBrown },
  FulfillmentCompleted: { background: Colors.lightGreen, label: Colors.darkGreen },

  // Payment Status
  Unpaid: { background: Colors.softRed, label: Colors.vividRed },
  PartiallyPaid: { background: Colors.brightYellow, label: Colors.mutedBrown },
  FullyPaid: { background: Colors.lightGreen, label: Colors.darkGreen },
  PartiallyRefunded: { background: Colors.brightOrange, label: Colors.white },
  FullyRefunded: { background: Colors.softRed, label: Colors.vividRed },
  Cancelled: { background: Colors.softRed, label: Colors.vividRed },
  AwaitingPayment: { background: Colors.brightYellow, label: Colors.mutedBrown },
  PendingPaymentAuthorization: { background: Colors.brightYellow, label: Colors.mutedBrown },
};
