export const Colors = {
  lightBlue: 'hsl(200, 60%, 85%)',
  grayishBlue: 'hsl(205, 26%, 61%)',
  softBlue: 'hsl(207, 53%, 86%)',
  vividBlue: 'hsl(207, 90%, 54%)',
  brightYellow: 'hsl(40, 100%, 73%)',
  mutedBrown: 'hsl(30, 30%, 38%)',
  paleYellow: 'hsl(48, 100%, 83%)',
  lightGreen: 'hsl(91, 77%, 80%)',
  darkGreen: 'hsl(106, 43%, 35%)',
  lightGray: 'hsl(0, 0%, 96%)',
  darkGrayBlue: 'hsl(210, 8%, 38%)',
  softRed: 'hsl(0, 83%, 90%)',
  vividRed: 'hsl(0, 67%, 46%)',
  brightOrange: 'hsl(36, 100%, 60%)',
  white: 'hsl(0, 0%, 100%)',
};
